<template>
  <div class="legal">
     <!-- <div class="background"></div> -->
    <v-container>
      <v-row class="rowContent justify-center">
        <v-col class="content_style py-16 px-6" cols="12" sm="8">
           <p class="titleName text-md-h3 text-h4 mb-10" style="color:#ffffff">
             {{ $t('about.p2') }}
          </p>
          <p class="text-h6 mb-4">
            {{ $t('about.p3') }}
          </p>
          <div v-for="(item, key) in content" :key="key">
            <div class="textStyle">
              <p class="text-h6 mb-4">
                {{ item.name}}
                <a :id="key">
                   {{ item.link }}
                </a>
                <a>{{ item.description }}</a>
              </p>
              <textarea id="input"></textarea>
              <img src="/static/img/common/copyButton.png" @click="copyText(key)">
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { aboutMediaContent } from '../configs/about-content'
export default {
  data() {
    return {
      // content : aboutMediaContent[this.$i18n.locale]
    }
  },
  computed:{
    content(){
      return aboutMediaContent[this.$i18n.locale]
    }
  },
  methods: {
    copyText(id){
      var text = document.getElementById(id).innerText
      var input = document.getElementById("input")
      input.value = text; // 修改文本框的内容
      input.select() // 选中文本
      document.execCommand("copy") // 执行浏览器复制命令
      alert("Copy Successed");
    }
  }
}
</script>
<style lang="scss" scoped>
.legal{
  position: relative;
  background-image: url('/static/img/common/policyBackground.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  .rowContent{
    margin-bottom:190px !important;
  }
  .content_style{
    z-index: 3;
    flex: none;
    max-width: 1100px !important;
    margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #3c2860;
  }
  .titleName {
    font-size: 50px;
    color: #8e88ff;
    font-weight: 900;
  }
  h2 {
    color: #8e88ff;
    margin: 60px 0 30px 0;
  }
  .textStyle{
    max-width: 620px;
    width:100%;
    height: 50px;
    #input { position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;}
    p{
      float: left;
    }
    img{
      float: right;
    }
  }
  p {
    color: #8e88ff;
    &.titleName{
      font-family: 'Source Han Sans-bold' !important;
      font-size: 3.75rem;
    }
  }
  a{
    color:#7dcd80;
  }
}
@media only screen and (max-width: 680px) {
  .legal{
    .textStyle{
      width: 100%;
      p{
        max-width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .legal{
    .textStyle{
      width: 100%;
      p{
        max-width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
