// multi-lang content in help page
export const aboutMediaContent = {
  jp: [
    {
      name : 'Twitter:',
      link: '@mahjongpet'
    },
    {
      name : 'メール：',
      link: 'riichicitysupport@mahjong-jp.com'
    },
    {
       name : '電話番号：',
      link: '05088839659'
    },
    {
      name : '営業時間：',
      link: '9:00～18:00'
    }
  ],
  en: [
    {
      name : 'Twitter: ',
      link: '@mahjongpet'
    },
    {
      name : 'Email:',
      link: 'riichicitysupport@mahjong-jp.com'
    }
  ],
  kr: [
     {
      name : 'Twitter: ',
      link: '@mahjongpet'
    },
    {
      name : 'E-mail：',
      link: 'riichicitysupport@mahjong-jp.com'
    }
  ],
  tc: [
    {
      name : 'Line@ (委员会): ',
      link: 'https://www.mahjong-jp.com/zh/linegroup'
    },
    {
      name : 'Twitter：',
      link: '@mahjongpet',
      description:'(這是《麻雀一番街》的官方客服號)'
    },
    {
      name : '電子信箱：',
      link: 'riichicitysupport@mahjong-jp.com'
    }
  ]
}
